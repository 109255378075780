import Cookies from 'js-cookie';
import { getCookieConsentValue } from 'react-cookie-consent';
import ReactGA from 'react-ga4';

import { CONSENT_COOKIE_NAME } from './constants';

export function hasCookieConsent() {
  return getCookieConsentValue(CONSENT_COOKIE_NAME) === 'true';
}

export function hasDeclinedCookieConsent() {
  return getCookieConsentValue(CONSENT_COOKIE_NAME) === 'false';
}

export function sendPageViewGA() {
  if (hasCookieConsent()) {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
  }
}

export function sendEventGA(event) {
  if (hasCookieConsent()) {
    ReactGA.event({
      category: event.category,
      action: event.action,
      label: event.label,
    });
  }
}

export function initGA() {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  sendPageViewGA();
}

export function createConsentCookie() {
  Cookies.set(CONSENT_COOKIE_NAME, 'true', {
    sameSite: 'Strict',
    secure: process.env.NODE_ENV !== 'development',
  });
  initGA();
}

export function removeAllCookies() {
  const cookies = document.cookie.split('; ');

  cookies.forEach((cookie) => {
    const cookieName = cookie.split('=')[0];
    Cookies.remove(cookieName, { path: '/', domain: window.location.hostname });
  });
}
